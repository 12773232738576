import { OrganizationFeaturesEnum, OrganizationPermissionEnum } from '@vegga-statics/enums';
import i18next from 'i18next';
import { environment } from '../environments/environment';
import { IMenuOptions, IModulesMenuOptions } from './interfaces/menuOptions';

export const menuOptions: IMenuOptions = {
  dashboard: {
    flags: environment.FLAGS.DISPLAY_DASHBOARD,
    icon: 'dashboard',
    submodules: [],
    title: i18next.t('menu.dashboard'),
    url: () => 'home',
  },
  users: {
    flags: environment.FLAGS.DISPLAY_USERS,
    feature: OrganizationFeaturesEnum.ORGANIZATION_USERS_ALLOWED,
    icon: 'users',
    permissions: OrganizationPermissionEnum.LIST_USERS,
    submodules: [],
    title: i18next.t('menu.users'),
    url: () => 'users',
  },
  advisers: {
    context: 'advisers',
    flags: environment.FLAGS.DISPLAY_ADVISERS,
    feature: OrganizationFeaturesEnum.ADVISERS_ENABLED,
    icon: 'users',
    moduleName: 'advisers',
    permissions: OrganizationPermissionEnum.LIST_LINKED_ADVISERS,
    submodules: [
      {
        option: 'modules.advisers.my_advisers',
        url: 'advisers/my-advisers',
        feature: OrganizationFeaturesEnum.ADVISERS_SUBMODULE_MY_ADVISERS_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_ADVISERS_SUBMODULE_MY_ADVISERS,
        flags: environment.FLAGS.DISPLAY_ADVISERS_SUBMODULE_MY_ADVISERS,
      },
    ],
    title: i18next.t('menu.advisers'),
  },
  farms: {
    flags: environment.FLAGS.DISPLAY_FARMS,
    feature: OrganizationFeaturesEnum.DIGITAL_FARMS_ENABLED,
    icon: 'collage',
    permissions: OrganizationPermissionEnum.ACCESS_DIGITAL_FARMS,
    submodules: [],
    title: i18next.t('menu.farms'),
    url: () => '/digital-farms',
  },
  maps: {
    flags: environment.FLAGS.DISPLAY_MAPS,
    feature: OrganizationFeaturesEnum.IRRIGATION_CONTROL_MAP_ENABLED,
    icon: 'maps',
    permissions: OrganizationPermissionEnum.ACCESS_MAPS,
    submodules: [],
    title: i18next.t('menu.maps'),
    url: () => '/irrigation-control/maps',
  },
  crops: {
    flags: environment.FLAGS.DISPLAY_CROPS,
    feature: OrganizationFeaturesEnum.IRRIGATION_CONTROL_CROPS_ENABLED,
    icon: 'leaf',
    permissions: OrganizationPermissionEnum.ACCESS_CROPS,
    submodules: [],
    title: i18next.t('menu.crops'),
    url: () => '/irrigation-control/crops',
  },
  subscriptions: {
    external: true,
    flags: environment.FLAGS.DISPLAY_MARKET,
    feature: OrganizationFeaturesEnum.MARKETPLACE_ENABLED,
    icon: 'cart',
    permissions: OrganizationPermissionEnum.ACCESS_MARKETPLACE,
    submodules: [],
    title: i18next.t('menu.subscriptions'),
    url: (token, language) => {
      return `${environment.URL.ECOMMERCE}/${language}/azure-ad/login?token=${token}`;
    },
  },
};

export const modulesMenuOptions: IModulesMenuOptions = {
  irrigationControl: {
    context: 'irrigation-control',
    feature: OrganizationFeaturesEnum.IRRIGATION_CONTROL_ENABLED,
    flags: environment.FLAGS.DISPLAY_IRRIGATION_CONTROL,
    icon: 'drop',
    moduleName: 'irrigation-control',
    permissions: OrganizationPermissionEnum.ACCESS_IRRIGATION_CONTROL,
    title: i18next.t('modules.irrigation-control.title'),
    submodules: [
      {
        option: 'modules.irrigation-control.menu.units',
        url: 'irrigation-control/units',
        feature: OrganizationFeaturesEnum.IRRIGATION_CONTROL_SUBMODULE_UNITS_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_IRRIGATION_CONTROL_SUBMODULE_UNITS,
        flags: environment.FLAGS.DISPLAY_IRRIGATION_CONTROL_SUBMODULE_UNITS,
      },
      {
        option: 'modules.irrigation-control.menu.breakdowns',
        url: 'irrigation-control/malfunctions',
        feature: OrganizationFeaturesEnum.IRRIGATION_CONTROL_SUBMODULE_MALFUNCTIONS_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_IRRIGATION_CONTROL_SUBMODULE_MALFUNCTIONS,
        flags: environment.FLAGS.DISPLAY_IRRIGATION_CONTROL_SUBMODULE_MALFUNCTIONS,
      },
      {
        option: 'modules.irrigation-control.menu.graphics',
        url: 'irrigation-control/graphics',
        feature: OrganizationFeaturesEnum.IRRIGATION_CONTROL_SUBMODULE_GRAPHICS_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_IRRIGATION_CONTROL_SUBMODULE_GRAPHICS,
        flags: environment.FLAGS.DISPLAY_IRRIGATION_CONTROL_SUBMODULE_GRAPHICS,
      },
      {
        option: 'modules.irrigation-control.menu.audits',
        url: 'irrigation-controls/audits',
        feature: OrganizationFeaturesEnum.IRRIGATION_CONTROL_SUBMODULE_AUDITS_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_IRRIGATION_CONTROL_SUBMODULE_AUDITS,
        flags: environment.FLAGS.DISPLAY_IRRIGATION_CONTROL_SUBMODULE_AUDITS,
      },
      {
        option: 'modules.irrigation-control.menu.export',
        url: 'irrigation-controls/exports',
        feature: OrganizationFeaturesEnum.IRRIGATION_CONTROL_SUBMODULE_EXPORT_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_IRRIGATION_CONTROL_SUBMODULE_EXPORT,
        flags: environment.FLAGS.DISPLAY_IRRIGATION_CONTROL_SUBMODULE_EXPORT,
      },
    ],
  },
  irrigationManager: {
    context: 'irrigation-manager',
    feature: OrganizationFeaturesEnum.IRRIGATION_MANAGER_ENABLED,
    flags: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER,
    icon: 'water-flash',
    moduleName: 'irrigation-manager',
    permissions: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER,
    submodules: [],
    title: i18next.t('modules.irrigation-manager.title'),
    url: () => '',
  },
  irrigationManagerEarlyAccess: {
    context: 'ea-irrigation-manager',
    feature: OrganizationFeaturesEnum.IRRIGATION_MANAGER_EARLY_ACCESS_ENABLED,
    flags: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_EARLY_ACCESS,
    icon: 'water-flash',
    moduleName: 'irrigation-manager-preview',
    permissions: OrganizationPermissionEnum.EARLY_ACCESS_IRRIGATION_MANAGER,
    submodules: [],
    title: i18next.t('modules.irrigation-manager.title'),
    url: () => '',
  },
  farmManagement: {
    context: 'farm-management',
    feature: OrganizationFeaturesEnum.FARM_MANAGEMENT_ENABLED,
    flags: environment.FLAGS.DISPLAY_FARM_MANAGEMENT,
    icon: 'book-open',
    moduleName: 'farm-management',
    permissions: OrganizationPermissionEnum.ACCESS_FARM_MANAGEMENT,
    title: i18next.t('modules.farm-management.title'),
    submodules: [
      {
        option: 'modules.farm-management.menu.farms',
        url: 'farm-management/explotaciones/listado',
        feature: OrganizationFeaturesEnum.FARM_MANAGEMENT_SUBMODULE_FARMS_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_FARM_MANAGEMENT_SUBMODULE_FARMS,
        flags: environment.FLAGS.DISPLAY_FARM_MANAGEMENT_SUBMODULE_FARMS,
      },
      {
        option: 'modules.farm-management.menu.configuration',
        url: 'farm-management/configuration',
        feature: OrganizationFeaturesEnum.FARM_MANAGEMENT_SUBMODULE_CONFIG_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_FARM_MANAGEMENT_SUBMODULE_CONFIG,
        flags: environment.FLAGS.DISPLAY_FARM_MANAGEMENT_SUBMODULE_CONFIG,
      },
    ],
  },
  irridesk: {
    context: 'irrigation-control',
    feature: OrganizationFeaturesEnum.IRRIDESK_ENABLED,
    flags: environment.FLAGS.DISPLAY_IRRIDESK,
    icon: 'branch',
    moduleName: 'irridesk',
    permissions: OrganizationPermissionEnum.ACCESS_IRRIGATION_CONTROL,
    title: i18next.t('modules.irridesk.title'),
    submodules: [
      {
        option: 'modules.irridesk.menu.channels',
        url: 'irrigation-control/expertirridesk/channels',
        feature: OrganizationFeaturesEnum.IRRIDESK_SUBMODULE_CHANNELS_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_IRRIDESK_SUBMODULE_CHANNELS,
        flags: environment.FLAGS.DISPLAY_IRRIDESK_SUBMODULE_CHANNELS,
      },
      {
        option: 'modules.irridesk.menu.sensors',
        url: 'irrigation-control/expertirridesk/sensors',
        feature: OrganizationFeaturesEnum.IRRIDESK_SUBMODULE_SENSORS_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_IRRIDESK_SUBMODULE_SENSORS,
        flags: environment.FLAGS.DISPLAY_IRRIDESK_SUBMODULE_SENSORS,
      },
    ],
  },
  pestControl: {
    context: 'pest-control',
    flags: environment.FLAGS.DISPLAY_PEST_CONTROL,
    icon: 'bug',
    moduleName: 'pest-control',
    submodules: [],
    title: i18next.t('modules.pest-control.title'),
    url: () => 'pest-control',
  },
  nutrition: {
    context: 'nutrition',
    flags: environment.FLAGS.DISPLAY_NUTRITION,
    icon: 'branch',
    moduleName: 'nutrition',
    submodules: [],
    title: i18next.t('modules.nutrition.title'),
    url: () => 'nutrition',
  },
  climaPlantasol: {
    context: 'irrigation-control',
    feature: OrganizationFeaturesEnum.WEATHER_PLANT_SOIL_ENABLED,
    flags: environment.FLAGS.DISPLAY_CLIMA_PLANTA_SOL,
    icon: 'branch',
    moduleName: 'weather-plant-soil',
    permissions: OrganizationPermissionEnum.ACCESS_WEATHER_PLANT_SOIL,
    title: i18next.t('modules.clima-planta-sol.title'),
    submodules: [
      {
        option: 'modules.clima-planta-sol.menu.agroclimatic',
        url: 'irrigation-control/agroclimatic-indices/waterdisp',
        feature: OrganizationFeaturesEnum.WEATHER_PLANT_SOIL_SUBMODULE_AGROCLIMATIC_INDEX_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_WEATHER_PLANT_SOIL_SUBMODULE_AGROCLIMATIC_INDEX,
        flags: environment.FLAGS.DISPLAY_WEATHER_PLANT_SOIL_SUBMODULE_AGROCLIMATIC_INDEX,
      },
      {
        option: 'modules.clima-planta-sol.menu.graphics',
        url: 'irrigation-control/agroclimatic-indices/graphics',
        feature: OrganizationFeaturesEnum.WEATHER_PLANT_SOIL_SUBMODULE_GRAPHICS_ENABLED,
        permissions: OrganizationPermissionEnum.ACCESS_WEATHER_PLANT_SOIL_SUBMODULE_GRAPHICS,
        flags: environment.FLAGS.DISPLAY_WEATHER_PLANT_SOIL_SUBMODULE_GRAPHICS,
      },
    ],
  },
  agrigeniusVite: {
    context: '',
    feature: OrganizationFeaturesEnum.AGRIGENIUS_VITE_ENABLED,
    external: true,
    flags: environment.FLAGS.DISPLAY_AGRIGENIUS_VITE,
    icon: 'grapes',
    moduleName: 'agrigenius-vite',
    submodules: [],
    permissions: OrganizationPermissionEnum.ACCESS_AGRIGENIOUS_VITE,
    title: i18next.t('modules.agrigenius.vite.title'),
    url: (token, language) => {
      return `${environment.URL.BASF}/horta_vite/faces/horta_vegga_auth.xhtml?token=${token}&lang=${language}`;
    },
  },
  agrigeniusOlivo: {
    context: '',
    feature: OrganizationFeaturesEnum.AGRIGENIUS_OLIVO_ENABLED,
    external: true,
    flags: environment.FLAGS.DISPLAY_AGRIGENIUS_OLIVO,
    icon: 'olive',
    moduleName: 'agrigenius-olivo',
    submodules: [],
    permissions: OrganizationPermissionEnum.ACCESS_AGRIGENIOUS_OLIVO,
    title: i18next.t('modules.agrigenius.olivo.title'),
    url: (token, language) => {
      return `${environment.URL.BASF}/horta_olivo/faces/horta_vegga_auth.xhtml?token=${token}&lang=${language}`;
    },
  },
};
