import { useEffect, useMemo, useState } from "react";
// @ts-ignore
import { getAppLanguage, getToken } from "@vegga/front-utils";
import { Organization } from "@vegga-types/organization";
//@ts-ignore
import { AuthStore } from "@vegga/front-store";
import Organizations from "./components/organizations/organizations.component";
import i18next from "./i18n/i18n.config";
import "./sidebar.component.scss";
import { environment } from "../environments/environment";

import { BrowserRouter as Router } from "react-router-dom";
import { menuOptions, modulesMenuOptions as modulesMenuOptionsList } from "./menu.options";
import { MenuItem } from "./components/menu-item";

import { MultiOptionsMenuItem } from "./components/multi-options-menu-item";
import { IOption } from "./interfaces/menuOptions";
import { debounce } from "./utils";
import { OrganizationFeaturesEnum, OrganizationPermissionEnum } from "@vegga-statics/enums";

const irrigationManagerFeatures = [
  { eaUrl: 'ea-irrigation-manager/admin"', url: "/admin", value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_ADMIN_ENABLED },
  { eaUrl: 'ea-irrigation-manager/', url: "/", value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_DASHBOARD_ENABLED },
  { eaUrl: 'ea-irrigation-manager/farms', url: "/farms", value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_FARMS_ENABLED },
  { eaUrl: 'ea-irrigation-manager/solid', url: "/solid", value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_SOLIDS_ENABLED },
  { eaUrl: 'ea-irrigation-manager/stocks', url: "/stocks", value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_FERTILIZERS_ENABLED },
  { eaUrl: 'ea-irrigation-manager/submit', url: "/submit", value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_SEND_PROGRAM_ENABLED },
  { eaUrl: 'ea-irrigation-manager/results', url: "/results", value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_RESULTS_ENABLED },
  { eaUrl: 'ea-irrigation-manager/settings', url: "/settings", value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_CONFIG_ENABLED },
  { eaUrl: 'ea-irrigation-manager/clientAdmin', url: "/clientAdmin", value: OrganizationFeaturesEnum.IRRIGATION_MANAGER_SUBMODULE_ADMIN_CLIENT_ENABLED },
];
const irrigationManagerFlags = [
  { eaUrl: 'ea-irrigation-manager/admin"', url: "/admin", value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_ADMIN },
  { eaUrl: 'ea-irrigation-manager/', url: "/", value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_DASHBOARD },
  { eaUrl: 'ea-irrigation-manager/farms', url: "/farms", value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_FARMS },
  { eaUrl: 'ea-irrigation-manager/solid', url: "/solid", value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_SOLIDS },
  { eaUrl: 'ea-irrigation-manager/stocks', url: "/stocks", value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_FERTILIZERS },
  { eaUrl: 'ea-irrigation-manager/submit', url: "/submit", value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_SEND_PROGRAM },
  { eaUrl: 'ea-irrigation-manager/results', url: "/results", value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_RESULTS },
  { eaUrl: 'ea-irrigation-manager/settings', url: "/settings", value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_CONFIG },
  { eaUrl: 'ea-irrigation-manager/clientAdmin', url: "/clientAdmin", value: environment.FLAGS.DISPLAY_IRRIGATION_MANAGER_SUBMODULE_ADMIN_CLIENT },
];

const irrigationManagerPermissions = [
  { eaUrl: 'ea-irrigation-manager/admin"', url: "/admin", value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_ADMIN },
  { eaUrl: 'ea-irrigation-manager/', url: "/", value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_DASHBOARD },
  { eaUrl: 'ea-irrigation-manager/farms', url: "/farms", value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_FARMS },
  { eaUrl: 'ea-irrigation-manager/solid', url: "/solid", value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_SOLIDS },
  { eaUrl: 'ea-irrigation-manager/stocks', url: "/stocks", value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_FERTILIZERS },
  { eaUrl: 'ea-irrigation-manager/submit', url: "/submit", value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_SEND_PROGRAM },
  { eaUrl: 'ea-irrigation-manager/results', url: "/results", value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_RESULTS },
  { eaUrl: 'ea-irrigation-manager/settings', url: "/settings", value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_CONFIG },
  { eaUrl: 'ea-irrigation-manager/clientAdmin', url: "/clientAdmin", value: OrganizationPermissionEnum.ACCESS_IRRIGATION_MANAGER_SUBMODULE_ADMIN_CLIENT },
];

const Sidebar = () => {
  const authStore = AuthStore.getInstance();

  const locale = getAppLanguage();
  i18next.changeLanguage(locale);

  const [token, setToken] = useState("");
  const [modulesMenuOptions, setModulesMenuOptions] = useState(modulesMenuOptionsList);

  const [isMenuShownOnMobile, setIsMenuShownOnMobile] = useState(false);

  const [organization, setOrganization] = useState<Organization | null>(null);
  const [lastOpenedModule, setLastOpenedModule] = useState("");

  const [isSidebarFolded, setIsSidebarFolded] = useState(false);
  const [hoveredItem, setHoveredItem] = useState("");

  const ecommerceLanguage = useMemo(() => (locale === "es" || locale === "en" ? locale : "en"), [locale]);

  useEffect(() => {
    getAuthToken();
    getOrganization();

    window.addEventListener("CLOSE_SIDEBAR", () => {
      setIsMenuShownOnMobile(false);
      setLastOpenedModule("");
    });

    return () => {
      window.removeEventListener("CLOSE_SIDEBAR", () => {
        setIsMenuShownOnMobile(false);
        setLastOpenedModule("");
      });
    };
  }, []);

  useEffect(() => {
    if (token && locale) {
      getIrrigationManagerMenu(locale, token);
      getIrrigationManagerEAMenu(locale, token);
    }
  }, [token, locale]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      debounce(() => {
        if (window.innerWidth < 992) {
          setIsSidebarFolded(false);
        }
      }, 200)
    );

    return () => {
      window.removeEventListener(
        "resize",
        debounce(() => {
          if (window.innerWidth < 992) {
            setIsSidebarFolded(false);
          }
        }, 200)
      );
    };
  }, []);

  const getAuthToken = async () => {
    const token = await getToken();
    setToken(token);
  };

  const getOrganization = () => {
    authStore.get$().subscribe((org: Organization) => setOrganization(org));
  };

  //TODO - Remove after finish the migration Irrigation Manager software
  const getIrrigationManagerMenu = async (locale: string, token: string) => {
    const data = JSON.stringify({
      query: `
      mutation {
        getImMenu(vgToken: "${token}", lang: "${locale}") {
          menu {
              option
              url
          }
        }
      }`,
    });
    try {
      const response = await fetch(environment.URL.API_IRRIGATION_MANAGER, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const { data: optionsResponse } = await response.json();
      const options = optionsResponse.getImMenu.menu.map((option: IOption) => {
        const feature = irrigationManagerFeatures.find((feature) => feature.url === option.url || feature.eaUrl === option.url);
        const flags = irrigationManagerFlags.find((flag) => flag.url === option.url || flag.eaUrl === option.url);
        const permissions = irrigationManagerPermissions.find((permission) => permission.url === option.url || permission.eaUrl === option.url);

        return {
          ...option,
          url: `${environment.BASE_URL_IRRIGATION_MANAGER}${option.url}`,
          feature: feature.value,
          flags: flags.value,
          permissions: permissions.value,
        };
      });

      if (data) {
        setModulesMenuOptions((modulesMenuOptionsList) => {
          return {
            ...modulesMenuOptionsList,
            ["irrigationManager"]: {
              ...modulesMenuOptionsList.irrigationManager,
              submodules: [...options],
            },
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getIrrigationManagerEAMenu = async (locale: string, token: string) => {
    const data = JSON.stringify({
      query: `
      mutation {
        getImMenu(vgToken: "${token}", lang: "${locale}") {
          menu {
              option
              url
          }
        }
      }`,
    });
    try {
      const response = await fetch(environment.URL.API_IRRIGATION_MANAGER_EA, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const { data: optionsResponse } = await response.json();

      const options = optionsResponse.getImMenu.menu.map((option: IOption) => {
        const feature = irrigationManagerFeatures.find((feature) => feature.url === option.url || feature.eaUrl === option.url);
        const flags = irrigationManagerFlags.find((flag) => flag.url === option.url || flag.eaUrl === option.url);
        const permissions = irrigationManagerPermissions.find((permission) => permission.url === option.url || permission.eaUrl === option.url);
        return {
          ...option,
          url: `${environment.BASE_URL_IRRIGATION_MANAGER_EA}${option.url}`,
          feature: feature.value,
          flags: flags.value,
          permissions: permissions.value,
        };
      });
      if (data) {
        setModulesMenuOptions((modulesMenuOptionsList) => {
          return {
            ...modulesMenuOptionsList,
            ["irrigationManagerEarlyAccess"]: {
              ...modulesMenuOptionsList.irrigationManagerEarlyAccess,
              submodules: [...options],
            },
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleMenuInMobile = (isFiredByToggler = false) => {
    setIsMenuShownOnMobile(() => (isFiredByToggler ? !isMenuShownOnMobile : false));
  };

  useEffect(() => {
    window.addEventListener("TOGGLE_SIDEBAR", () => {
      setIsSidebarFolded((isSidebarFolded) => !isSidebarFolded);
    });

    return () => {
      window.removeEventListener("TOGGLE_SIDEBAR", () => {
        setIsSidebarFolded((isSidebarFolded) => !isSidebarFolded);
      });
    };
  }, []);

  return (
    <Router>
      <div
        className={
          isSidebarFolded ? "vegga-sidebar__container vegga-sidebar__container--folded" : "vegga-sidebar__container"
        }>
        <div
          className={`vegga-sidebar__hamburger ${isMenuShownOnMobile ? "vegga-sidebar__hamburger--animation" : ""}`}
          onClick={() => toggleMenuInMobile(true)}>
          <vegga-icon icon="arrow-down"></vegga-icon>
        </div>
        <div className={`vegga-sidebar__content ${isMenuShownOnMobile ? "vegga-sidebar__content--show" : ""}`}>
          {authStore.getNumberOfOrganizations() > 1 && <Organizations></Organizations>}
          <div className="vegga-sidebar__menu-items">
            {/* NAV ITEMS */}

            {Object.values(menuOptions).map((option, index) => {
              const { title, icon, context, moduleName, submodules, permissions, feature, flags, url, external } =
                option;
              return submodules?.length ? (
                <MultiOptionsMenuItem
                  key={index}
                  title={title}
                  icon={icon}
                  context={context}
                  moduleName={moduleName}
                  options={submodules}
                  lastOpenedModule={lastOpenedModule}
                  setLastOpenedModule={setLastOpenedModule}
                  permissions={permissions}
                  feature={feature}
                  flags={flags}
                  toggleMenuInMobile={toggleMenuInMobile}
                  isSidebarFolded={isSidebarFolded}
                  hoveredItem={hoveredItem}
                  setHoveredItem={setHoveredItem}
                />
              ) : (
                <MenuItem
                  key={index}
                  label={title}
                  icon={icon}
                  url={url}
                  external={external}
                  permissions={permissions}
                  feature={feature}
                  flags={flags}
                  token={token}
                  language={ecommerceLanguage}
                  toggleMenuInMobile={toggleMenuInMobile}
                  isSidebarFolded={isSidebarFolded}
                  hoveredItem={hoveredItem}
                  setHoveredItem={setHoveredItem}
                />
              );
            })}
          </div>

          {/* MODULES */}

          <div className="vegga-sidebar__modules">
            <div className="vegga-sidebar__modules-title">
              {!isSidebarFolded && <span>{i18next.t("modules.title")}</span>}
              <vegga-icon icon="dashboard"></vegga-icon>
            </div>
            <div className="vegga-sidebar__modules-items">
              {Object.values(modulesMenuOptions).map((module, index) => {
                const { title, icon, context, moduleName, submodules, permissions, feature, flags, url, external } =
                  module;

                return submodules?.length ? (
                  <MultiOptionsMenuItem
                    key={index}
                    title={title}
                    icon={icon}
                    context={context}
                    moduleName={moduleName}
                    options={submodules}
                    lastOpenedModule={lastOpenedModule}
                    setLastOpenedModule={setLastOpenedModule}
                    permissions={permissions}
                    feature={feature}
                    flags={flags}
                    toggleMenuInMobile={toggleMenuInMobile}
                    isSidebarFolded={isSidebarFolded}
                    hoveredItem={hoveredItem}
                    setHoveredItem={setHoveredItem}
                    isModule
                  />
                ) : (
                  <MenuItem
                    key={index}
                    label={title}
                    icon={icon}
                    url={url}
                    external={external}
                    permissions={permissions}
                    feature={feature}
                    flags={flags}
                    token={token}
                    language={ecommerceLanguage}
                    moduleName={moduleName}
                    toggleMenuInMobile={toggleMenuInMobile}
                    isSidebarFolded={isSidebarFolded}
                    hoveredItem={hoveredItem}
                    setHoveredItem={setHoveredItem}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Sidebar;
